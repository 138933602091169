<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
 Update Client

       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addFormvalidate">
      <b-form class="mt-2" style="width: 60%" @submit.prevent="save">
        <b-row>
        
          <b-col md="12">
            <b-form-group
              label="Client  Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Course Name"
                 
                >
              <b-form-input id="blog-edit-title" v-model="addForm.name" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col md="12">
            <b-form-group
              label="Client Website Link"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="In-Link Name"
                 
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addForm.link"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
         
          <b-col cols="12" class="mb-2">
           <div class="border rounded p-2">
              <h4 class="mb-1">Client Logo</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                     :src="`https://api.lmitac.com/${addForm.image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                 
                  <b-card-text class="my-50"> </b-card-text>
                  <div class="d-inline-block">
                  <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                   <validation-provider
                  #default="{ errors }"
                  name="Logo "
                 
                >
                    <b-form-file
                      ref="refInputE2"
                      v-model="addForm.image"
                      accept=".jpg, .png, .gif, .jpeg"
                      placeholder="Choose file"
                      @input="inputImageRendererTab"
                    />
                     <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addForm.alt_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
               @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive,computed } from "@vue/composition-api";
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
        const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);

      const Tabimage = ref("media/svg/files/blank-image.svg");
           const { route } = useRouter()
      const id = route.value.params.id
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
  
      store.dispatch('clients/GetClient',{id})
        .then(response => {
         
           store.commit('clients/GET_CLIENT', response?.data.data)
          })
 
    const Form = computed(() => store.state.clients.client);
    const addForm=reactive(Form)
 
   
   const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
       
        refPreviewE2.value.src = base64;
      }
    );
    
     const addFormvalidate=ref()

    const save = () => {
      
   
      const formData = new FormData();

    
        
         addFormvalidate.value.validate().then(success => {
        if (success) {
        

formData.append("image", addForm.value.image);
formData.append("name", addForm.value.name);
formData.append("link", addForm.value.link);
formData.append("alt_image", addForm.value.alt_image);
formData.append("_method", "put");
      console.log("formData",formData)
           store.dispatch('clients/UpdateClient',{id,formData})
        .then(response => {
           Vue.swal({
                       title: "Client Update ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      
        }})
    };
    return {
    refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
     
      Tabimage,
      addForm,
      inputImageRenderer,
      addFormvalidate,
      id,
      Form,
      addForm,
   
     

      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }
  
  </style>